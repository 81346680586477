<template>
  <div>
    <a-form layout="inline" class="flex justify-end pb-2">
      <a-form-item class="mr-0">
        <a-button icon="plus-circle" @click="add">新增楼栋</a-button>
      </a-form-item>
    </a-form>
    <a-table
      class="queryView-table"
      :rowKey="tableRowKey"
      bordered
      :loading="loading"
      :columns="tableColumns"
      :dataSource="dataSource"
      :pagination="false"
      size="middle"
      :expandIconAsCell="false"
      :expandIconColumnIndex="0"
      :expandedRowRender="tableExpandedRowRender"
      @expand="tableExpand"
      :expandedRowKeys.sync="expandedRowKeys"
    ></a-table>
    <base-pagination
      size="small"
      :totalCount.sync="totalCount"
      :pageNo.sync="pageNo"
      :pageSize.sync="pageSize"
      @change="pageChange"
    />
    <!-- 添加/编辑弹框 -->
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="区域">
          <a-input disabled :value="project.areaName"></a-input>
        </a-form-item>
        <a-form-item label="项目名称">
          <a-input disabled :value="project.innerProjectName"></a-input>
        </a-form-item>
        <a-form-item label="楼栋名称">
          <a-input
            v-decorator="[
              'buildingName',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
            placeholder="请输入楼栋名称"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
    <SelectUmsUser ref="selectUmsUser"></SelectUmsUser>
  </div>
</template>
<script>
import { queryViewMixin } from '@/utils/mixin'
import { groupList } from '../../services/commonTypes'
import SelectUmsUser from '../../modules/SelectUmsUser'
import _ from 'lodash'
export default {
  components: {
    SelectUmsUser,
  },
  mixins: [queryViewMixin],
  props: {
    project: {
      require: true,
      type: Object,
    },
  },
  computed: {
    typesMap() {
      return {
        groupList,
      }
    },
  },
  data() {
    const tableColumns = [
      {
        title: '',
        width: '2%',
        align: 'center',
      },
      {
        title: '楼栋编码',
        width: '15%',
        align: 'center',
        dataIndex: 'buildingCode',
      },
      {
        title: '楼栋名称',
        width: '20%',
        align: 'center',
        dataIndex: 'buildingName',
      },
      {
        title: '操作',
        width: '15%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.edit(row)
                  }}
                >
                  编辑
                </a>
              </span>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.remove(row)
                  }}
                >
                  删除
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      tableRowKey: 'id',
      dataSource: [],
      expandedRowKeys: [],
      selectedRowKeys: [],
      selectedRows: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        // data里面，多条的信息，一般不包含id字段（通过id区分是批量操作还是单条操作）。单条情况下可以 === 原始数据列
        data: {},
        // 需要批量操作需要原始数据列的时候使用。批量操作的原始数据列一般情况下是selectedRows的子集
        rows: [],
      },
    }
  },
  watch: {
    project: {
      handler(v) {
        this.conditionalQuery()
      },
      immediate: true,
    },
  },
  methods: {
    async tableExpand(expanded, record) {
      const { tableRowKey, dataSource } = this
      const currentGroupCode = ['k01', 'k02']
      if (expanded) {
        const res = await this.$axios.post('/fast-repair/admin/projectUserPermission/queryByCode', {
          commonCode: record.buildingCode,
          groupCode: currentGroupCode.join(','),
        })
        const userList = res.data
        const userGroup = {}
        currentGroupCode.map(code => {
          userGroup[code] = []
        })
        userList.map(user => {
          const group = user.groupCode
          if (!userGroup[group]) {
            userGroup[group] = [user]
          } else {
            userGroup[group].push(user)
          }
        })
        const rowIndex = dataSource.findIndex(row => row[tableRowKey] === record[tableRowKey])
        this.$set(this.dataSource[rowIndex], 'userGroup', userGroup)
      }
      // console.log(expanded, record)
    },
    async addGroupUser(tableIndex, groupCode, commonCode, permissionLevel = 3) {
      try {
        const row = await this.$refs.selectUmsUser.start()
        await this.$axios.post('/fast-repair/admin/projectUserPermission/add', {
          commonCode,
          groupCode,
          permissionLevel,
          umsAccountId: row.accountId,
          umsAccountName: row.userName,
        })
        this.$message.success('操作成功!')
      } catch (e) {}
      // 打开了用户界面，可能会有改名称的操作，所以无论成功失败，都刷新
      this.tableExpand(true, this.dataSource[tableIndex])
    },
    async deleteGroupUser(tableIndex, id) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '确认删除?',
          content: '用户将从当前组移除',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/fast-repair/admin/projectUserPermission/delete', { id })
      this.$message.success('操作成功!')
      this.tableExpand(true, this.dataSource[tableIndex])
    },
    tableExpandedRowRender(record, tableIndex) {
      const userGroupList = []
      if (record.userGroup) {
        for (const groupCode in record.userGroup) {
          const groupName = this.translateType(groupCode, 'groupList')
          userGroupList.push({
            groupCode: groupCode,
            groupName: groupName,
            list: record.userGroup[groupCode],
          })
        }
      }
      return (
        <div class="usergroup-list">
          {userGroupList.map(groupItem => {
            return (
              <div class="usergroup-item">
                <div class="usergroup-item-hd">
                  <span>{groupItem.groupName}</span>
                  <a-icon
                    type="plus-circle"
                    class="action-icon"
                    onClick={() => {
                      this.addGroupUser(tableIndex, groupItem.groupCode, record.buildingCode)
                    }}
                  />
                </div>
                <div class="usergroup-user-list">
                  {groupItem.list.map((item, index) => {
                    return (
                      <div key={index} class="usergroup-user-item">
                        <a-icon type="user" />
                        <span>{item.umsAccountName}</span>
                        <a-icon
                          type="minus-circle"
                          class="action-icon"
                          onClick={() => {
                            this.deleteGroupUser(tableIndex, item.id)
                          }}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      )
    },
    onRowSelectChange(keys, rows) {
      this.selectedRowKeys = keys
      this.selectedRows = rows
    },
    conditionalQuery() {
      if (!_.isEmpty(this.project)) {
        this.doQuery()
      } else {
        this.resetTable()
      }
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            const { id } = this.modal.data
            const actionUrl = id
              ? '/fast-repair/admin/projectBuilding/update'
              : '/fast-repair/admin/projectBuilding/add'
            await this.$axios.post(actionUrl, {
              id,
              ...params,
              areaCode: this.project.areaCode,
              innerProjectCode: this.project.innerProjectCode,
            })
            this.$message.success('操作成功!')
            // 关闭弹框
            this.modal.visible = false
            // 重置勾选
            this.onRowSelectChange([], [])
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    add() {
      if (_.isEmpty(this.project)) {
        return this.$error({ title: '请先选择项目' })
      }
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增楼栋'
    },
    async edit(row) {
      this.modal.visible = true
      this.modal.title = '编辑楼栋'
      this.modal.data = { ...row }
      this.safeSetFieldsValue(this.modal.form, { ...row })
    },
    async remove(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确认删除?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/fast-repair/admin/projectBuilding/delete', {
        id: row.id,
      })
      this.$message.success('操作成功!')
      this.doQuery()
    },
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/fast-repair/admin/projectBuilding/page', {
          ...this.getPageParams(_pageNo, _pageSize),
          innerProjectCode: this.project.innerProjectCode,
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
        this.expandedRowKeys = []
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    resetTable() {
      this.pageNo = 1
      this.totalCount = 0
      this.dataSource = []
    },
  },
  mounted() {},
}
</script>
<style lang="less" scoped>
.usergroup-item {
  position: relative;
  .usergroup-item-hd {
    height: 40px;
    display: flex;
    align-items: center;
  }
  &:before {
    content: '';
    position: absolute;
    left: 10px;
    width: 0;
    top: 40px;
    bottom: 20px;
    border-left: 1px dashed #999;
  }
  .action-icon {
    color: @primary-color;
    margin-left: 15px;
    cursor: pointer;
    font-size: 20px;
  }
  .usergroup-user-list {
    color: #999;
    padding-top: 20px;
    .usergroup-user-item {
      position: relative;
      height: 40px;
      padding-left: 40px;
      display: flex;
      align-items: center;
      &:before {
        content: '';
        position: absolute;
        left: 10px;
        width: 30px;
        top: 20px;
        height: 0;
        border-top: 1px dashed #999;
      }
    }
  }
}
</style>
